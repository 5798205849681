@import "../../../../Config/variables";

@media only screen and (max-width: 830px){
  .picklist-element{
    font-size: 14px !important;
  }
}
.picklist-element{
  border: 1.5px solid $accent500;
  width: 100%;
  height: 50px;
  margin-top: 10px;
  background-color: $accent300;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 17px;
  transition: 300ms;
  cursor: pointer;

  &.selected{
    background-color: $accent500;
    color: #fff;
  }

  &:hover:not(&.selected){
    color: $accent500;
    transition: 300ms;
  }

  p{
    text-align: left;
  }

  .letter{
    color: $accent500;
    background-color: #fff;
    border: 1.5px solid $accent500;
    border-radius: 8px;
    height: 30px;
    width: 27px;
    margin-left: 13px;
    margin-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    flex-shrink: 0;
  }
}