@import "../Config/variables";

.result-page{

    .content{
        margin: 25px 12vw;
    }

    .flex-div{
        display: flex;
        align-items: center;
    }

    .image-person{
        height: 60px;
        width: 60px;
        border-radius: 50px;
        margin-right: 25px;
    }

    .welcome-text{
        font-size: 17px;
        margin-top: 25px;
    }

    .title-simulation-results{
        font-size: 24px;
        text-decoration: underline;
    }

    .we-will-call-you{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4986e020;
        border-radius: 10px;
        padding: 0 15px;
        max-width: 800px;
        margin-top: 30px;

        p{
            font-size: 18px;
            margin-left: 10px;
        }
    }

    .steps-title{
        text-decoration: underline;
        font-size: 22px;
    }

    .step{
        display: flex;
        flex-direction: column;
        justify-content: center;

        .line1{
            display: flex;
            align-items: center;

            .icon-number{
                flex-shrink: 0;
                height: 30px;
                width: 30px;
                border-radius: 30px;
                background-color: #000;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
                font-weight: 600;
            }

            p{
                font-weight: 600;
                font-size: 18px;
            }
        }

        .line2{
            margin-left: 45px;
            font-size: 16px;
        }

        &.colored{
            .line1, .line2{
                color: $accent500;
                .icon-number{
                    background-color: $accent500;
                }

                p{
                    color: $accent500;
                }
            }
        }
    }

    
}